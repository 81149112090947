@tailwind base;
@tailwind components;
@tailwind utilities;

.theme-nav-title {
  @apply text-2xl font-bold;
}

.table-border {
  @apply border text-center text-lg p-2 print:text-xs print:border-black print:px-1 print:py-0;
}

.table-border-yellow {
  @apply table-border border-yellow-400 print:border-black;
}

.table-border-total {
  @apply table-border border-purple-400 print:border-black;
}

.table-border-male {
  @apply table-border border-blue-400 print:border-black;
}

.table-border-female {
  @apply table-border border-pink-400 print:border-black;
}

.result-table-border {
  @apply border p-2;
}

@media screen {
	.print-component {
		visibility: hidden;
		display: none;
	}
}

@media print {
	.print-component {
		visibility: visible;
	}
}
